@use '../global'

section.news
	position: relative
	z-index: 100
	//background: Global.$primaryColor
	background: rgba(30, 30, 30, 1)
	padding: 100px 0

	h2
		position: relative
		z-index: 5
		display: block
		margin: 0 auto 50px
		padding: 0 30px
		width: 300px
		//background: black
		//border: solid 1px black
		//border-radius: 30px
		border-bottom: solid 1px #ccc
		color: black
		font-size: 60px
		font-weight: 100
		text-align: center
		line-height: 60px

	.articles
		display: flex
		position: relative
		z-index: 100
		margin: 0 auto
		width: 1400px

		.article
			position: relative
			flex: 1
			margin: 0 50px 0 0
			padding-bottom: 70px
			//background: white
			//box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2)
			color: black
			opacity: 0
			transform: translateY(100px)
			transition: 1s

			&:last-child
				margin-right: 0

			.cover
				position: relative
				margin: 0 0 25px
				padding-top: 66%
				background: #ddd

				&:before
					content: ''
					position: absolute
					z-index: 1
					bottom: 0
					left: 0
					width: 100%
					height: 5px
					background: linear-gradient(to right, #175098, #398339)

				&:after
					content: ''
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
					background: linear-gradient(105deg, rgba(51, 100, 176, 0.4), rgba(86, 97, 112, 0.2))
					mix-blend-mode: multiply

				img
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
					object-fit: cover

			.published
				position: relative
				margin: 0 0 5px
				color: white
				font-size: 12px
				font-weight:  800

			.tab
				position: relative
				//background: black
				color: white
				font-size: 16px
				font-weight: 100

			.info
				display: none
				padding: 30px

				.title
					margin-bottom: 5px
					font-size: 1.5em
					text-align: center

				.published
					margin: 0 20px 20px
					padding-bottom: 10px
					color: #888
					font-size: 0.9em
					text-align: center
					border-bottom: solid 1px rgba(0, 0, 0, 0.2)

				.body
					margin-bottom: 20px
					font-weight: 100
					text-align: justify

				a
					display: block
					margin: 0 auto
					padding: 12px 15px
					width: fit-content
					//background: rgba(0, 0, 0, 0.5)
					background: linear-gradient(to bottom right, #67ae67, #579f81)
					//border: solid 1px black
					border-radius: 0
					color: white

			.link
				position: absolute
				display: inline-block
				bottom: 0
				left: 0
				margin: 30px 0 0
				padding: 0 15px
				background: rgba(255, 255, 255, 0.7)
				border-radius: 0
				color: #333
				font-size: 13px
				line-height: 30px

			&.enter
				opacity: 1
				transform: translateY(0)

				@for $x from 0 to 5
					&:nth-child(#{$x})
						transition-delay: 0.3s * ($x - 1)


@media all and (max-width: 1600px)

	section.news

		.articles
			margin: 0 50px
			width: auto

			.article
				margin: 0 30px 0 0

@media all and (max-width: global.$breakpointPhone)

	section.news

		.articles
			display: block
			margin: 0 30px

			.article
				margin: 0 0 40px
				padding: 0

				.link
					position: relative
