@use '../global'

section.wrapper

	.gallery
		display: flex
		flex-wrap: wrap

		figure
			float: left
			margin: 0 20px 20px 0
			width: calc((100% - 20px) / 2)

			&:nth-of-type(2n)
				margin: 0 0 20px

@media all and (max-width: global.$breakpointPhone)

	section.wrapper

		.gallery
			display: block

			figure
				float: none
				margin: 0 0 20px
				width: 100%
