.wrapper :global(.banner2)
	height: 300px

	h1
		left: 50%
		color: #069
		font-size: 30px
		font-weight: normal
		transform: translateX(-50%)




.wrapper section
	width: 900px

@media all and (max-width: 760px)

	.wrapper section

		width: auto





.profile
	padding: 20px 0
	text-align: center

	caption
		display: block
		color: #888

@media all and (max-width: 760px)

	.profile

		img
			width: 100%



.signature
	margin-right: 20%
	text-align: right

	img
		width: 150px


