a.tile
	display: flex
	margin-bottom: 20px
	padding-bottom: 20px
	border-bottom: solid 1px #ccc
	color: black

	div
		flex: 1

		&:last-child
			text-align: right

@media all and (max-width: 760px)

	a.tile
		display: block

		div

			&:last-child
				margin-top: 5px
				font-size: 0.9em
				text-align: left
