a.tile
	display: block
	margin: 0 30px 30px 0
	width: calc((100% - 60px) / 3)
	box-shadow: 0 5px 10px rgba(0,0,0,0.2)
	color: black

	&:nth-child(3n)
		margin-right: 0

	.cover
		position: relative
		padding-top: 70%
		width: 100%
		background: #eee
		overflow: hidden

		img
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			transform: scale(1) translateZ(0)
			transition: 0.5s ease-in-out
			object-fit: cover

	&:hover .cover img
		transform: scale(1.05) translateZ(0)

	.text
		padding: 15px
		border-top: solid 2px #069

@media all and (max-width: 1020px)

	a.tile
		width: calc((100% - 30px) / 2)

		&:nth-child(3n)
			margin-right: 30px

		&:nth-child(2n)
			margin-right: 0

@media all and (max-width: 760px)

	a.tile, a.tile:nth-child(n)
		position: relative
		margin: 0 0 30px
		width: auto
