@use '../global'

.wrapper
	position: fixed
	z-index: 1001
	top: 0
	left: 0
	width: 100%
	height: 100px

	header
		position: relative
		height: 105px
		width: 100%
		background: rgba(19, 61, 105, 0)
		transition: 0.75s

	&.sticky

		header
			height: 60px
			background: rgba(19, 61, 105, 1)
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)




a.logo
	position: fixed
	z-index: 8

	.bg
		position: absolute
		z-index: 0
		top: -15px
		left: -10px
		width: 250px
		height: 100px
		background: linear-gradient(to right, #069, #065273)
		border-radius: 10px
		transform: skew(-15deg)

	img
		position: absolute
		z-index: 1
		top: 23px
		left: 35px
		height: 45px
		transition: 0.75s

.sticky

	a.logo

		img
			top: 14px
			height: 35px

@media all and (max-width: global.$breakpointPhone)

	a.logo

		img
			left: 15px
			height: 40px







// Function bar

.functionBar
	position: fixed
	top: 0
	height: 50px
	width: 100%
	//background: rgba(30, 30, 30, 0.8)

	.bg
		display: none
		position: absolute
		top: -10px
		right: -50px
		height: calc(100% + 10px)
		width: calc(100vw - 240px + 50px)
		background: linear-gradient(100deg, rgba(40, 40, 40, 1), rgba(80,80,80,0.8))
		border-radius: 10px
		transform: skew(15deg)

@media all and (max-width: 1200px)

	.menuBtn
		position: fixed
		z-index: 100
		top: 15px
		right: 15px
		padding: 0
		width: 50px
		height: 50px
		transition-duration: 1s
		cursor: pointer

		span
			content: ''
			position: absolute
			top: 24px
			right: 10px
			height: 2px
			width: 30px
			background: #ddd
			border-radius: 1px
			overflow: hidden
			transition-duration: 0.5s
			transition-timing-function: ease-in-out
			transform-origin: center

			&:hover
				background: white

			&:first-child
				top: 14px
				transform: rotate(0deg)

			&:last-child
				top: 35px
				transform: rotate(0deg)

	.expanded .menuBtn span

		&:first-child
			top: 50%
			transform: rotate(-136deg)

		&:nth-child(2)
			width: 0
			opacity: 0

		&:last-child
			top: 50%
			transform: rotate(136deg)

	.sticky .menuBtn
		top: 2px
		right: 10px
		transform: scale(0.8)







//.panelBg

@media all and (max-width: global.$breakpointPhone)

	.panelBg
		position: fixed
		z-index: 9
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: linear-gradient(to bottom, rgba(80,80,80,0.95), rgba(40,40,40,0.95))
		opacity: 0
		transition: 0.5s
		pointer-events: none

	.expanded .panelBg
		opacity: 1
		pointer-events: all





.panel
	position: fixed
	z-index: 1
	top: 0
	right: 0

	nav
		position: relative
		float: right
		margin: 0
		text-align: center
		transition: 0.75s

		.navTop
			display: inline-block
			position: relative
			transition-duration: 0.3s
			vertical-align: middle
			cursor: pointer

			@for $x from 1 through 10

				&:nth-child(#{$x})
					z-index: 10 - $x

			.line
				position: absolute
				top: 70px
				left: 50%
				width: 0
				height: 2px
				background: #468a5a
				transform: translateX(-50%)
				transition: 0.5s

			> a
				display: block
				padding: 0 15px
				color: white
				font-size: 1em
				font-weight: bold
				line-height: 100px
				opacity: 0.7
				transition: 0.75s

				span[ln="zh"]
					font-size: 0.9em
					letter-spacing: 0.15em

			.dropDown
				position: absolute
				top: 100px
				right: 0
				width: 200px
				background: linear-gradient(to right, rgba(255,255,255,0.7), rgba(255,255,255,0.6))
				border-radius: 5px
				color: black
				text-align: left
				opacity: 0
				transform: scaleY(0)
				transform-origin: top
				transition: top 0.75s, transform 0.3s
				overflow: hidden

				a
					display: block
					margin: 0
					padding: 15px 20px
					border-bottom: solid 1px rgba(0, 0, 0, 0.2)
					color: black
					font-size: 0.85em
					//line-height: 50px
					text-align: right
					opacity: 0

					&:first-child
						padding-top: 25px

					&:last-child
						padding-bottom: 25px
						border: none

			&:hover

				.line
					width: calc(100% - 20px)

				a
					opacity: 1

				.dropDown
					opacity: 1
					transform: scaleY(1)

					a
						opacity: 1
						transition: opacity 0.3s 0.3s, color 0.3s, background 0.3s

						&:hover
							color: white
							background: #1476b8

.sticky .panel

	nav

		.navTop

			.line
				top: 55px
				height: 5px

			> a
				line-height: 60px

			.dropDown
				top: 60px
				border-radius: 0

nav.collapsed

	.navTop
		width: 1px
		height: 100px
		opacity: 0
		transition: opacity 0s

		a
			opacity: 0

.sticky nav.collapsed

	.navTop
		height: 60px


@media all and (max-width: 1200px)

	.panel, .sticky .panel
		z-index: 10
		width: calc(100% - 50px)
		max-width: 500px
		height: 100%
		background: #111
		transform: translateX(100%)
		transition: 0.5s cubic-bezier(0.9, 0, 0.1, 1)
		overflow-y: scroll

		nav
			float: none
			padding: 0 0 50px
			text-align: left

			.navTop
				display: block

				.line
					display: none

				a
					padding: 15px 30px
					line-height: 2em

				.dropDown
					position: relative
					top: 0
					right: 0
					margin: 0 0 15px
					width: auto
					background: none
					transform: none
					opacity: 1

					a
						padding: 5px 30px
						color: white
						text-align: left
						line-height: 2em
						opacity: 1

	.expanded .panel
		transform: translateX(0)







.collapsedIcon
	display: inline-block
	opacity: 0
	transition: 0.5s
	cursor: pointer

	span
		display: inline-block
		margin: 0 1.5px
		width: 4px
		height: 4px
		border-radius: 50%
		background: white

nav.collapsed

	.collapsedIcon
		opacity: 1



.dropDownShade
	position: absolute
	top: 105px
	right: 30px
	width: 1200px
	height: 200px
	background: white






.searchBar
	float: right
	margin: 35px 45px 0 30px
	transition: 0.75s

	input
		margin-right: 0
		padding: 0
		width: 0
		background: rgba(255, 255, 255, 0.05)
		border: solid 1px rgba(255, 255, 255, 0.7)
		border-radius: 12px
		color: white
		font-size: 11px
		line-height: 24px
		vertical-align: middle
		opacity: 0
		transition: 0.5s cubic-bezier(0.9, 0, 0.1, 1)

		&::placeholder
			color: rgba(255, 255, 255, 0.5)

		&:focus
			outline: none

	img
		position: relative
		top: 0px
		height: 19px
		vertical-align: middle

	&.active

		input
			margin-right: 10px
			padding: 0 20px
			width: 300px
			opacity: 1

.sticky .searchBar
	margin-top: 17px










.languageSwitcher
	float: right
	margin: 35px 40px 0 0
	color: white
	transition: 0.75s

	.switch
		display: inline-block
		position: relative
		padding: 0 5px
		height: 24px
		border: solid 1px rgba(255, 255, 255, 0.7)
		border-radius: 12px
		overflow: hidden
		vertical-align: middle
		cursor: pointer

		span
			display: inline-block
			width: 40px
			font-size: 10px
			text-align: center
			text-transform: uppercase
			opacity: 0.5

			&.active
				opacity: 1

			&:nth-child(2)
				font-size: 11px
				letter-spacing: 1px

		.slider
			position: absolute
			top: 0
			left: 0
			width: 50%
			height: 100%
			background: rgba(255, 255, 255, 0.2)
			transition: 0.3s

			&.right
				left: 50%

	img
		margin-left: 10px
		height: 18px
		filter: invert(1)
		vertical-align: middle


.sticky .languageSwitcher
		margin-top: 17px

@media all and (max-width: 1200px)

	.languageSwitcher
		margin: 35px 90px 0 0
