.wrapper

	img
		display: block
		margin: 40px auto
		width: auto

@media all and (max-width: 760px)

	.wrapper

		img
			width: 100%
			height: auto
