@use '../global'







.footer
	position: relative
	z-index: 1000
	padding: 50px
	background: #181e26

@media all and (max-width: global.$breakpointPhone)

	.footer
		padding: 50px 30px







.columns
	display: flex
	margin: 0 0 100px

	.column
		flex: 1
		margin: 0 20px

		a
			display: block
			padding: 5px 0
			color: rgba(255, 255, 255, 0.7)
			font-size: 0.9em

		.heading
			margin: 30px 0
			padding: 0 0 15px
			border-bottom: solid 1px rgba(255,255,255,0.2)
			color: rgba(255, 255, 255, 1)
			font-weight: bold

			&:first-child
				margin-top: 0

@media all and (max-width: 1200px)

	.columns
		flex-flow: wrap

		.column
			flex: none
			margin: 0 20px 50px
			width: calc((100% / 3) - 40px)

@media all and (max-width: global.$breakpointPhone)

	.columns
		display: block

		.column
			margin: 0
			width: auto

			.heading, .heading:first-child
				margin: 40px 0 20px







.byline
	color: #ccc
	font-size: 0.8em
	text-align: center

	span
		display: inline-block
		margin: 0 10px

@media all and (max-width: global.$breakpointPhone)

	.byline
		text-align: left

		span
			margin: 0 15px 0 0
