@use '../global'

.banner
	position: relative
	height: 100vh
	min-height: 800px

	video, > img
		position: fixed
		z-index: 0
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover

	.overlay
		position: absolute
		z-index: 1
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: linear-gradient(to top left, rgba(64, 136, 120, 0.2), rgba(30, 30, 30, 0.6) 40%, rgba(30, 30, 30, 0.6) 60%, rgba(27, 103, 134, 0.6))
		//background: radial-gradient(rgba(30, 30, 30, 0.8) 60%, rgba(27, 103, 134, 0.5))

	.fade
		position: absolute
		z-index: 2
		bottom: 0
		left: 0
		width: 100%
		height: 200px
		background: linear-gradient(to bottom, rgba(30, 30, 30, 0), rgba(30, 30, 30, 1))

	.content
		display: flex
		align-items: center
		position: fixed
		z-index: 2
		top: 50%
		left: 0
		width: 100%
		color: white
		text-align: right
		transform: translateY(-50%)

		.text
			flex: 1
			margin-right: 50px

			h1
				margin: 0 0 30px
				padding: 0
				color: white
				font-size: 80px
				line-height: 1.3em
				text-transform: uppercase

		.categories
			flex: 1

			.tiles
				display: flex
				flex-wrap: wrap
				z-index: 2
				width: 400px
				color: white

				a
					display: block
					margin: 0 20px 20px 0
					padding: 0
					width: 150px
					height: 150px
					background: rgba(255,255,255,0.2)
					border: solid 1px rgba(255, 255, 255, 0.7)
					border-radius: 8px
					text-align: center
					opacity: 0.9
					transition: 0.3s cubic-bezier(0.8, 0, 0.2, 1)
					cursor: pointer

					&:last-child
						margin: 0

					.icon
						margin: 30px auto 20px
						width: 50px
						height: 50px
						border-radius: 50%
						//background: rgba(255,255,255,1)
						font-size: 10px
						line-height: 50px

						img
							width: 100%
							height: 100%
							filter: invert(1)
							transition: 0.5s
							object-fit: contain

					&:hover
						background: rgba(255, 255, 255, 0.85)
						color: #0d3554
						opacity: 1
						transform: scale(1.1)

						.icon img
							filter: invert(0)

@media all and (max-width: global.$breakpointPhone)

	.banner

		.content
			display: block

			.text
				margin: 50px 0 30px
				text-align: center

				h1
					font-size: 40px

			.categories
				flex: none

				.tiles
					//display: block
					margin: 25px 0 0 55px
					width: auto

					a
						width: 120px
						height: 120px

						.icon
							margin: 15px auto






section.info
	position: relative
	z-index: 2
	display: flex
	background: rgb(30, 30, 30)

	.bg
		position: absolute
		z-index: 1
		top: 0
		left: 0
		width: 100%
		height: calc(100% + 500px)
		background: #333
		//background: linear-gradient(to bottom, #aaa, #fff)

		img
			width: 100%
			height: 100%
			opacity: 0.1
			filter: saturate(0)

		.fade
			position: absolute
			bottom: 0
			left: 0
			width: 100%
			height: 1000px
			background: linear-gradient(to bottom, rgba(0, 102, 153, 0), rgba(0, 102, 153, 1))

	.panel
		position: relative
		z-index: 2
		margin: -150px auto 0
		//min-height: 900px
		width: 1200px
		background: linear-gradient(to bottom right, #1884be, #069)
		border-radius: 10px
		box-shadow: 0 0 15px rgba(30, 30, 30, 0.7)
		//transform: translateY(-300px)
		overflow: hidden

		.tab
			position: relative
			margin: 0
			padding: 0 50px
			height: 145px
			background: #04405b
			border-bottom: dotted 1px rgba(255, 255, 255, 0.5)
			color: white
			font-size: 36px
			font-weight: bold
			line-height: 145px

			.icon
				position: absolute
				top: calc(50% - 15px)
				right: 50px
				width: 30px
				height: 30px
				background: rgba(255, 255, 255, 0.5)
				border-radius: 50%
				color: #04405b
				text-align: center
				line-height: 15px

				&:after
					content: '⌄'

		.intro
			display: flex
			margin: 50px

			.introVideo
				position: relative
				width: 400px
				//width: 800px
				//background: black
				cursor: pointer

				img
					position: absolute
					z-index: 1
					top: 50%
					left: 50%
					width: 100px
					transform: translate(-50%, -50%)
					opacity: 1
					transition-duration: 0.5s

				video
					width: 100%
					//opacity: 0.6
					transition-duration: 0.5s

				&.active

					img
						opacity: 0

					video
						opacity: 1

			.text
				flex: 1
				margin-left: 30px
				color: white

				h2
					margin: 0 0 25px
					padding: 0
					font-size: 30px
					font-weight: bold



		.features
			display: none
			margin: 50px
			border: dotted 1px white
			border-radius: 10px
			color: white
			text-align: center
			line-height: 400px





	.block
		position: relative
		flex: 1
		background: lightblue fixed
		background-size: cover

		&:before
			content: ''
			position: absolute
			z-index: 1
			top: 0
			left: 0
			width: 100%
			height: 100%
			background: linear-gradient(to bottom right, rgba(34, 67, 121, 0.8), rgba(117, 250, 210, 0.5))
			mix-blend-mode: darken

		&:last-child:before
			background: linear-gradient(to bottom right, rgb(34, 55, 30), rgb(114, 167, 99))


		//img
		//	width: 100%
		//	height: 100%
		//	object-fit: cover

		.content
			position: absolute
			z-index: 1
			top: 0
			left: 0
			width: 100%
			height: 100%
			background: linear-gradient(to right, #111 400px, #fff 400px)
			mix-blend-mode: darken

			h2
				margin: 50px
				padding: 0
				color: white
				font-size: 100px
				font-weight: bold
				line-height: 1.2em

		.text
			position: absolute
			z-index: 1
			top: 320px
			left: 50px
			width: 300px
			color: #ccc

		.visit
			position: absolute
			z-index: 2
			bottom: 0
			left: 0
			padding: 20px 30px
			width: 320px
			background: linear-gradient(to bottom right, #202020, #222)
			font-size: 13px
			text-transform: uppercase
			letter-spacing: 0.1em

		&:first-child
			background-image: url(../images/home/solar-panel-overhead.jpg)

		&:last-child
			background-image: url(../images/home/shipping-containers-overhead.jpg)

@media all and (max-width: 1200px)

	section.info

		.panel
			width: auto
			border-radius: 0

@media all and (max-width: global.$breakpointPhone)

	section.info

		.panel
			border-radius: 0

			.intro
				display: block
				margin: 50px 30px

				.introVideo
					margin: 0 0 30px
					width: auto

					img
						width: 50px

				.text
					margin: 0





