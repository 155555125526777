@use '../global'

.options
	position: absolute
	bottom: 35px
	right: calc(50% - 600px)

	.tiles, .list
		float: left
		margin-left: 15px
		padding: 9px
		width: 16px
		height: 16px
		border: solid 1px white
		border-radius: 5px
		opacity: 0.5
		transition: 0.3s
		cursor: pointer

		&.active
			opacity: 1

	.tiles

		span
			float: left
			margin: 0 2px 2px 0
			width: 7px
			height: 7px
			background: white

			&:nth-child(2n)
				margin: 0 0 2px

	.list

		span
			display: block
			margin: 0 0 3px
			height: 3px
			background: white

@media all and (max-width: 1260px)

	.options
		right: 30px

@media all and (max-width: global.$breakpointPhone)

	.options
		right: 20px
		bottom: 30px
