@use '../global'

.links
	padding: 20px

a.link
	display: block
	margin: 0 0 5px
	padding: 15px 15px
	background: global.$primaryColor
	color: white
