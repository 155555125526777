@use "../global"

.pane
	position: absolute
	top: 100px
	left: 57px
	width: 340px

	input
		display: none

	.results
		display: none
		position: relative
		background: white
		border-radius: 5px

		.page
			display: block
			padding: 20px
			border-bottom: solid 1px #ccc
			color: black

	&.active

		.results
			display: block




@media all and (max-width: global.$breakpointPhone)

	.pane
		position: relative
		top: 0
		left: 0
		margin: 70px 0 0
		padding: 30px
		width: auto
		color: white

		input
			display: block
			margin: 0 0 20px
			padding: 7px 10px
			width: calc(100% - 20px)
			border: none
			border-radius: 3px

		.results
			display: block
			color: black




