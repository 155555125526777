@use '../global'


.details
	display: flex
	flex-wrap: wrap
	//margin: 0 0 50px
	//padding: 0 0 30px
	//border-bottom: solid 1px #ccc

	.item
		margin: 0 20px 20px 0
		width: calc((100% - 40px) / 3)

		&:nth-child(3n)
			margin: 0 0 20px

		label
			display: block
			margin: 10px 0 0
			padding: 5px 0 0
			border-top: solid 2px global.$primaryColor
			color: global.$primaryColor
			font-size: 10px

@media all and (max-width: global.$breakpointPhone)

	.details
		display: block

		.item
			margin: 0 0 20px
			width: auto



.tag
	display: inline-block
	margin: 50px 0 20px
	padding: 7px 10px
	width: 200px
	background: global.$primaryColor
	color: white
	font-size: 0.9em


.message
	margin: 80px auto 0
	padding: 18px 30px
	text-align: center
	width: 600px
	color: white

	&.success
		background: #279f48

	&.error
		background: #cb1818

.application
	margin: 30px auto 50px
	width: 600px
	padding: 30px 30px 40px
	background: #eee

	h2
		display: block
		margin-bottom: 30px
		padding-bottom: 8px
		border-bottom: solid 2px #555
		line-height: 1em

	label
		display: block
		margin: 0 0 10px

	input[type="text"], textarea
		display: block
		margin: 0 0 20px
		padding: 10px
		width: calc(100% - 22px)
		border: solid 1px #aaa

	textarea
		height: 160px

	input[type="file"]
		margin: 0 0 10px
		padding: 10px
		width: calc(100% - 22px)
		border: solid 1px #ccc

	legend
		margin: 0 0 30px
		color: #555555
		font-size: 12px

	input[type="submit"]
		display: block
		padding: 15px
		width: 100%
		background: global.$primaryColor
		border: none
		color: white
		text-transform: uppercase
		-webkit-appearance: none

	&.inactive
		opacity: 0.7

@media all and (max-width: global.$breakpointPhone)

	.message
		width: auto

	.application
		width: auto
