:global(.withSidebar)

	.awards
		display: block

		p
			display: block

			&:last-of-type
				margin-bottom: 50px

		figure
			display: block
			float: left
			margin: 0 20px 20px 0
			width: calc((100% - 40px) / 3)
			height: auto

			&:nth-of-type(3n)
				margin: 0 0 20px

			&:nth-of-type(3n + 1)
				clear: both

			img
				display: block
				width: auto

			figcaption
				display: block

@media all and (max-width: 760px)

	:global(.withSidebar)

		.awards
			display: block

			figure
				float: none
				margin: 0 0 30px
				width: auto
				height: auto
