//@import url("https://use.typekit.net/rju7lte.css")

html
	height: 100%

::-webkit-scrollbar
	display: none

body
	margin: 0
	height: 100%
	//background: #103c68
	background: white
	color: black
	font-family: 'basic-sans', '微软雅黑', 'Microsoft YaHei', Helvetica, Arial, sans-serif

	.zh
		letter-spacing: 0.1em

#root
	display: flex
	flex-direction: column
	min-height: 100vh

main
	flex: 1

h1
	margin: 0
	padding: 100px 0 0
	font-size: 40px

a
	color: white
	text-decoration: none

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)

	#root
		display: block

	main
		height: 100%








.banner
	position: relative
	height: 500px
	background: black

	img
		width: 100%
		height: 100%
		object-fit: cover

	.topFade
		position: absolute
		z-index: 1
		top: 0
		left: 0
		height: 200px
		width: 100%
		background: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.5))

	.fade
		position: absolute
		z-index: 1
		bottom: 0
		left: 0
		height: 200px
		width: 100%
		background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5))

	h1
		position: absolute
		z-index: 2
		bottom: 50px
		left: calc(50% - 550px)
		width: 1000px
		color: white

@media all and (max-width: 1100px)

	.banner

		h1
			left: 50px
			width: calc(100% - 100px)



section.fixed
	margin: 0 auto
	width: 1100px
	min-height: 800px

	figure
		margin: 0 auto
		width: 100%
		text-align: center

		&.table
			position: relative
			left: -5px
			border: solid 5px #069
			overflow: scroll

		table
			width: 100%
			text-align: left
			vertical-align: top
			border-spacing: unset

			td, th
				padding: 10px
				vertical-align: top

		//&.image-style-align-left
		//	clear: both
		//	float: left
		//	margin: 0 30px 30px 0
		//	width: auto

	img
		max-width: 100%

section.padded
	padding: 50px 30px


@media all and (max-width: 1160px)

	section.fixed
		margin: 0 30px
		width: auto

@media all and (max-width: 760px)

	.banner

		h1
			position: absolute
			left: 30px
			width: calc(100% - 60px)

	section.fixed
		width: auto

		figure.image-style-align-left
			clear: both
			float: none
			margin: 0 0 20px 0
			width: auto

		figure

			table
				width: 1100px

	section.padded
		padding: 50px 0


.banner2
	position: relative
	height: 400px
	//background: black

	.banner-image
		position: absolute
		opacity: 0.5

		> img
			position: relative
			top: 0
			left: 0
			width: 100%

		.banner-image-fade
			position: absolute
			bottom: 0
			left: 0
			width: 100%
			height: 500px
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0,0,0,1))

	.topFade
		position: absolute
		z-index: 1
		top: 0
		left: 0
		height: 200px
		width: 100%
		background: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.5))

	h1
		position: absolute
		bottom: 30px
		left: calc(50% - 630px)
		color: white

section.fixed2
	position: relative
	z-index: 2
	margin: 0 auto
	padding: 30px
	width: 1200px
	min-height: 800px
	background: white
	border-radius: 10px
	box-shadow: 0 5px 10px rgba(0,0,0,0.3)

	figure
		margin: 0

		img
			width: 100%

	figcaption
		color: #888
		text-align: center

.bg2
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100vh
	min-height: 1000px
	background: linear-gradient(to bottom, rgb(18, 97, 124), rgba(0,0,0, 0))

@media all and (max-width: 1300px)

	section.fixed2
		margin: 0 20px 100px
		width: auto

	.banner2 h1
		left: 20px

@media all and (max-width: 760px)

	.banner2
		height: 300px

		.banner-image

			> img
				width: 100vw
				height: 500px
				object-fit: cover

			.banner-image-fade
				height: 250px

		h1
			position: absolute
			bottom: 20px
			left: 20px

	section.fixed2
		margin: 0 15px 40px
		padding: 20px
		width: auto




.banner3
	position: relative
	height: 400px

	> img
		position: fixed
		top: 0
		left: 0
		width: 100%
		height: calc(100vh + 100px)
		object-fit: cover
		opacity: 0.3

	h1
		position: absolute
		bottom: 30px
		left: calc(50% - 600px)
		color: white

section.fixed3
	position: relative
	z-index: 2
	margin: 0 auto 100px
	padding: 0
	width: 1200px
	min-height: 800px
	color: white

@media all and (max-width: 1260px)

	.banner3

		h1
			left: 30px
			width: auto

	section.fixed3
		margin: 0 30px 100px
		width: auto

@media all and (max-width: 760px)

	.banner3
		height: 300px

		h1
			position: absolute
			bottom: 20px
			left: 20px


	section.fixed3
		width: auto



.withSidebar
	display: flex

	.sidebar
		margin-right: 50px
		width: 300px
		background: #eee

	.body
		flex: 1

		figure
			display: inherit
			margin: 0
			margin-block-start: 0
			margin-block-end: 0
			margin-inline-start: 0
			margin-inline-end: 0
			padding: 0
			width: 100%
			appearance: none
			-webkit-appearance: none

			img
				max-width: 100%

@media all and (max-width: 1100px)

	.withSidebar

		.sidebar
			width: 250px

@media all and (max-width: 1020px)

	.withSidebar
		flex-direction: column

		.sidebar
			order: 2
			margin: 0 0 30px
			width: auto

		.body
			order: 1


