@use '../global'

section.wrapper

	figure
		position: relative
		float: left
		margin: 0 20px 0 0
		width: calc((100% - 40px) / 3)

		&:nth-of-type(3n)
			margin: 0

		img
			width: 100%

	p
		clear: both

@media all and (max-width: global.$breakpointPhone)

	section.wrapper

		figure
			float: none
			margin: 0 0 20px
			width: auto

			&:nth-of-type(3n)
				margin: 0 0 20px

			img
				width: 100%

		p
			clear: both
