.back
	display: block
	margin: 0 0 20px
	color: #069

.image

	> img
		width: 100%

.description
	margin-top: 20px
