.pager
	margin: 50px 0
	text-align: center

	span
		display: inline-block
		margin: 0 5px
		width: 25px
		font-size: 12px
		font-weight: bold
		text-align: center
		line-height: 25px
		border: solid 2px #888
		border-radius: 3px
		opacity: 0.5
		transition: 0.3s
		cursor: pointer
		user-select: none

		&.active
			border-color: #069
			color: #069
			opacity: 1

