a.tile
	display: block
	margin: 0 20px 20px
	padding: 20px
	background: white
	color: black
	font-size: 0.9em

	.heading
		margin-bottom: 5px
		font-size: 1.2em
		font-weight: bold
