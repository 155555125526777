@use '../global'

.wrapper

	.byline
		display: flex
		margin-bottom: 50px
		padding-bottom: 20px
		border-bottom: solid 1px #ccc

		div
			flex: 1

			&:first-child
				text-align: left

			&:nth-child(2)
				text-align: center

.footer
	margin-top: 200px
	text-align: center

	div
		margin: 0 auto
		max-width: 500px
		padding: 10px
		background: #fafafa
		border: solid 1px #aaa
		border-radius: 5px
		transition: 0.3s
		cursor: pointer

		&:hover
			background: #fff

@media all and (max-width: global.$breakpointPhone)

	.wrapper

		.byline

			div

				&:nth-child(2)
					text-align: right

				&:last-child
					display: none

		h1
			font-size: 30px
