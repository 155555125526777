@use '../global'

.tile
	display: block
	margin: 0 50px 50px 0
	width: calc((100% - 100px) / 3)

	&:nth-child(3n)
		margin-right: 0

	.cover
		position: relative
		margin-bottom: 20px
		padding-top: 70%
		width: 100%
		background: #eee

		img
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			object-fit: cover

	.date
		display: inline-block
		margin: 0 0 10px
		//padding: 5px 10px
		width: 120px
		//background: #6d969e
		font-size: 12px
		font-weight: bold
		letter-spacing: 0em

	.title
		//margin: 0 0 5px
		font-weight: 100

@media all and (max-width: 1020px)

	.tile
		width: calc((100% - 50px) / 2)

		&:nth-child(3n)
			margin-right: 50px

		&:nth-child(2n)
			margin-right: 0

@media all and (max-width: global.$breakpointPhone)

	.tile, .tile:nth-child(n)
		position: relative
		margin: 0 0 30px
		width: auto





.list
	display: flex
	margin: 0 0 30px
	padding: 0 0 30px
	width: 100%
	border-bottom: solid 1px #ccc

	&:nth-child(3n)
		margin-right: 0

	.cover
		display: none

	.date
		display: block
		margin: 0
		width: 200px
		font-size: inherit
		font-weight: bold
		letter-spacing: 0em

	.title
		//margin: 0 0 5px
		font-weight: 100



@media all and (max-width: global.$breakpointPhone)

	.list
		display: block

		.date
			margin-bottom: 5px

