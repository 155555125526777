@use '../global'

section.wrapper

	figure
		display: flex
		margin: 0 0 30px
		padding: 0 0 30px
		width: 100%
		border-bottom: solid 1px #ccc

		img
			margin: 0 30px 0 0
			width: 250px

		figcaption
			flex: 1
			font-size: 14px
			text-align: left

@media all and (max-width: global.$breakpointPhone)

	section.wrapper

		figure
			display: block

			img
				margin: 0 0 20px
				width: 100%
