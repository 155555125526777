@use '../global'

.posts
	display: flex
	flex-wrap: wrap

@media all and (max-width: global.$breakpointPhone)

	.wrapper

		:global(.banner3)

			h1
				width: 80%

		.posts
			display: block
			padding: 0px
