@use '../global'

.tiles
	display: flex
	flex-wrap: wrap

@media all and (max-width: 760px)

	.tiles
		display: block
